@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Utopia';
    src: url('./assets/fonts/utopia-std-display.otf') format('opentype');
}

@font-face {
    font-family: 'Playfair';
    src: url('./assets/fonts/PlayfairDisplay-Regular.ttf') format('opentype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Playfair';
    src: url('./assets/fonts/PlayfairDisplay-Bold.ttf') format('opentype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Playfair';
    src: url('./assets/fonts/PlayfairDisplay-Italic.ttf') format('opentype');
    font-weight: 400;
    font-style: italic;
}